import { Companies } from 'routes/components/Features/Companies/Companies'
import classes from './LandingCompanies.module.scss'

export const LandingCompanies = () => {
  return (
    <section className={classes.section}>
      <Companies />
    </section>
  )
}
