import { Block } from 'ui-kit/blocks'
import { Heading } from 'ui-kit/typography'
import { IntegrationUXDataType } from './integrationUXData'
import clsx from 'clsx'
import classes from './IntegrationUX.module.scss'

export const IntegrationUXCard = ({
  item,
  className,
}: {
  item: IntegrationUXDataType
  className?: string
}) => {
  const { title, image1x, image2x } = item

  return (
    <div className={clsx('flex-column', classes.block, className)}>
      <Heading as={'h3'} size={'sm'} center className={classes.block_title}>
        {title}
      </Heading>
      <Block variant={'gradient'} r={'md'} className={classes.block_visual}>
        <picture className={classes.block_image}>
          <source media='(min-resolution: 2dppx)' srcSet={image2x.src} />
          <source srcSet={image1x.src} />
          <img
            src={image1x.src}
            alt={title}
            width={464}
            height={424}
            loading='lazy'
          />
        </picture>
      </Block>
    </div>
  )
}
