import { StaticImageData } from 'next/image'
import AppsIcon from './icons/icon-apps.svg'
import BlueprintsIcon from './icons/icon-blueprints.svg'
import DeploymentsIcon from './icons/icon-deployments.svg'
import LogIcon from './icons/icon-log.svg'
import AppsImageSm from './images/landing-ide-apps@1x.png'
import AppsImageMd from './images/landing-ide-apps@2x.png'
import AppsImageLg from './images/landing-ide-apps@3x.png'
import BlueprintsImageSm from './images/landing-ide-blueprints@1x.png'
import BlueprintsImageMd from './images/landing-ide-blueprints@2x.png'
import BlueprintsImageLg from './images/landing-ide-blueprints@3x.png'
import DeploymentsImageSm from './images/landing-ide-deployments@1x.png'
import DeploymentsImageMd from './images/landing-ide-deployments@2x.png'
import DeploymentsImageLg from './images/landing-ide-deployments@3x.png'
import LogImageSm from './images/landing-ide-log@1x.png'
import LogImageMd from './images/landing-ide-log@2x.png'
import LogImageLg from './images/landing-ide-log@3x.png'

export type TabType = {
  id: string
  title: string
  mobileTitle?: string
  icon?: StaticImageData
}

export type IDEDataType = TabType & {
  imageSm: StaticImageData
  imageMd: StaticImageData
  imageLg: StaticImageData
}

export const ideData: IDEDataType[] = [
  {
    id: 'apps',
    title: 'External Apps',
    icon: AppsIcon,
    imageSm: AppsImageSm,
    imageMd: AppsImageMd,
    imageLg: AppsImageLg,
  },
  {
    id: 'blueprints',
    title: 'Integration Blueprints',
    icon: BlueprintsIcon,
    imageSm: BlueprintsImageSm,
    imageMd: BlueprintsImageMd,
    imageLg: BlueprintsImageLg,
  },
  {
    id: 'deployments',
    title: 'Customer Deployments',
    icon: DeploymentsIcon,
    imageSm: DeploymentsImageSm,
    imageMd: DeploymentsImageMd,
    imageLg: DeploymentsImageLg,
  },
  {
    id: 'log',
    title: 'Activity Log',
    icon: LogIcon,
    imageSm: LogImageSm,
    imageMd: LogImageMd,
    imageLg: LogImageLg,
  },
]
