import { Heading, Text } from 'ui-kit/typography'
import { Certification } from 'routes/components/Features/Certification/Certification'
import { VendorLock } from 'routes/components/Features/VendorLock/VendorLock'
import clsx from 'clsx'
import classes from './LandingInfrastructure.module.scss'

export const LandingInfrastructure = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <Heading as={'h2'} size={'xl'} balance center className={classes.title}>
          Self-host or use our cloud
        </Heading>
        <Text size={'lg'} balance center className={classes.subtitle}>
          Use the self-hosted version of our product or let us manage the
          infrastructure. Your choice.
        </Text>
      </div>
      <Certification className={classes.certification} />
      <VendorLock />
    </section>
  )
}
