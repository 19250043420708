import { Heading, Text } from 'ui-kit/typography'
import { Link } from 'routes/components/Shared'
import { LINKS } from 'routes/constants'
import clsx from 'clsx'
import classes from './LandingNetwork.module.scss'

export function LandingNetwork({
  totalIntegrations,
}: {
  totalIntegrations: number
}) {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <Heading as={'h2'} size={'xl'} balance center className={classes.title}>
          Connect your app to everything else your customers use
        </Heading>
        <Text size={'lg'} balance center className={classes.subtitle}>
          We offer more supported integrations than any other solution and build
          connectors on demand with a reliable SLA.
        </Text>
      </div>

      <div className={classes.visual}>
        <div className={classes.wrapper}>
          <div className={classes.counter}>
            <Text center className={classes.counter_text}>
              Supported integrations:
            </Text>
            <div className={classes.counter_data}>
              <div className={classes.counter_number}>
                <Text
                  as='span'
                  size={'lg'}
                  className={classes.counter_number__base}
                >
                  000000
                </Text>
                <Text
                  as='span'
                  size={'lg'}
                  className={classes.counter_number__main}
                >
                  {totalIntegrations}
                </Text>
              </div>
            </div>
            <Text center className={classes.counter_text}>
              ...and counting
            </Text>
          </div>
        </div>
      </div>

      <div className={clsx('page__actions', classes.actions)}>
        {/* ↓↓↓ this link to the page that will be added in future ↓↓↓ */}
        {/* <Link
          className={clsx('page__button', classes.link)}
          type='primary'
          href={'#'}
        >
          Read more about our integration network
        </Link> */}
        <Link
          className={clsx('page__button', classes.link)}
          type='secondary'
          href={LINKS.INTEGRATIONS}
        >
          Explore Integrations
        </Link>
      </div>
    </section>
  )
}
