import { Heading, Text } from 'ui-kit/typography'
import { LINKS } from 'routes/constants'
import { Link } from 'routes/components/Shared'
import { UniversalIntegrations } from 'routes/components/Features/UniversalIntegrations'
import clsx from 'clsx'
import classes from './LandingIntegrations.module.scss'

export const LandingIntegrations = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <Heading as={'h2'} size={'xl'} balance center className={classes.title}>
          Write integration code once. Use it for any external app
        </Heading>
        <Text size={'lg'} balance center className={classes.subtitle}>
          Universal integration framework separates business logic and UI of any
          complexity from the details of each individual API. This is a new and
          better way of building integrations.
        </Text>
      </div>

      <UniversalIntegrations bgIsVisible />

      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={clsx('page__button', classes.link)}
          type='secondary'
          href={LINKS.UNIVERSAL_INTEGRATIONS}
        >
          Learn about Universal Integration Framework
        </Link>
      </div>
    </section>
  )
}
