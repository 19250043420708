import { IntegrationUX } from 'routes/components/Features/IntegrationUX'
import classes from './LandingUX.module.scss'

export const LandingUX = () => {
  return (
    <section className={classes.section}>
      <IntegrationUX />
    </section>
  )
}
