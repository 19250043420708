import DevelopmentMobImage1x from './img-mob@1x.avif'
import DevelopmentMobImage2x from './img-mob@2x.avif'
import DevelopmentDeskImage1x from './img-desk@1x.avif'
import DevelopmentDeskImage2x from './img-desk@2x.avif'
import clsx from 'clsx'
import classes from './Development.module.scss'

export const Development = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(classes.visual, className)}>
      <div className={classes.background} />
      <div className={classes.wrapper}>
        <picture className={classes.image}>
          <source
            media='(min-resolution: 2dppx) and (min-width: 768px)'
            srcSet={DevelopmentDeskImage2x.src}
          />
          <source
            media='(min-width: 768px)'
            srcSet={DevelopmentDeskImage1x.src}
          />
          <source
            media='(min-resolution: 2dppx) and (max-width: 767px)'
            srcSet={DevelopmentMobImage2x.src}
          />
          <source
            media='(max-width: 767px)'
            srcSet={DevelopmentMobImage1x.src}
          />
          <img
            src={DevelopmentMobImage1x.src}
            alt='Integration Engine'
            width={350}
            height={571}
            loading='lazy'
          />
        </picture>
      </div>
    </div>
  )
}
