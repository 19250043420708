import { StaticImageData } from 'next/image'
import UIDefaultImage1x from './ui-default@1x.png'
import UIDefaultImage2x from './ui-default@2x.png'
import UICustomImage1x from './ui-custom@1x.png'
import UICustomImage2x from './ui-custom@2x.png'

export type IntegrationUXDataType = {
  title: string
  image1x: StaticImageData
  image2x: StaticImageData
}

export const integrationUXData: IntegrationUXDataType[] = [
  {
    title: 'Use pre-built UI components',
    image1x: UIDefaultImage1x,
    image2x: UIDefaultImage2x,
  },
  {
    title: 'Build fully custom UI',
    image1x: UICustomImage1x,
    image2x: UICustomImage2x,
  },
]
