import { Heading, Text } from 'ui-kit/typography'
import { Link } from 'routes/components/Shared'
import { DOCS_LINK, LINKS } from 'routes/constants'
import { integrationUXData } from './integrationUXData'
import { IntegrationUXCard } from './IntegrationUXCard'
import clsx from 'clsx'
import classes from './IntegrationUX.module.scss'

export const IntegrationUX = ({ className }: { className?: string }) => {
  return (
    <div className={clsx(classes.wrapper, className)}>
      <Heading as={'h2'} size={'xl'} balance center className={classes.title}>
        Build integration UX your customers love
      </Heading>
      <div className={clsx('flex-column', classes.visual)}>
        <IntegrationUXCard
          item={integrationUXData[0]}
          className={classes.block__default}
        />
        <div className={classes.stroke}>
          <Text as={'span'} lh={'tight'} className={classes.text}>
            or
          </Text>
        </div>
        <IntegrationUXCard
          item={integrationUXData[1]}
          className={classes.block__custom}
        />
      </div>

      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={'page__button'}
          type='secondary'
          href={LINKS.INTEGRATION_UX}
        >
          Learn more
        </Link>
        <Link
          className={'page__button'}
          type='secondary'
          href={`${DOCS_LINK}/integration-ui`}
        >
          Read the Docs
        </Link>
      </div>
    </div>
  )
}
