import { Heading, Text } from 'ui-kit/typography'
import { CONNECTOR_DOCS_LINK } from 'routes/constants'
import { Link } from 'routes/components/Shared'
import InteractionMobImage1x from './landing-interaction-mob@1x.png'
import InteractionMobImage2x from './landing-interaction-mob@2x.png'
import InteractionDeskImage1x from './landing-interaction-desk@1x.png'
import InteractionDeskImage2x from './landing-interaction-desk@2x.png'
import clsx from 'clsx'
import classes from './LandingInteraction.module.scss'

export const LandingInteraction = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <Heading as={'h2'} size={'xl'} balance center className={classes.title}>
          Scale deep native integrations with Universal Connectors
        </Heading>
        <Text size={'lg'} balance center className={classes.subtitle}>
          Authenticate, access data and metadata, subscribe to events, and work
          with business objects in a consistent way across all integrations.
        </Text>
      </div>
      <div className={classes.visual}>
        <picture className={classes.image}>
          <source
            media='(min-resolution: 2dppx) and (min-width: 768px)'
            srcSet={InteractionDeskImage2x.src}
          />
          <source
            media='(min-width: 768px)'
            srcSet={InteractionDeskImage1x.src}
          />
          <source
            media='(min-resolution: 2dppx) and (max-width: 767px)'
            srcSet={InteractionMobImage2x.src}
          />
          <source
            media='(max-width: 767px)'
            srcSet={InteractionMobImage1x.src}
          />
          <img
            src={InteractionMobImage1x.src}
            alt='Interact with every app'
            width={480}
            height={688}
            loading='lazy'
          />
        </picture>
      </div>
      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={clsx('page__button', classes.link)}
          type='secondary'
          href={CONNECTOR_DOCS_LINK}
        >
          Learn more about Universal Connectors
        </Link>
      </div>
    </section>
  )
}
