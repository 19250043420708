import { Heading, Text } from 'ui-kit/typography'
import { Development } from 'routes/components/Features/Development'
import clsx from 'clsx'
import classes from './LandingDevelopment.module.scss'

export const LandingDevelopment = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <Heading as={'h2'} size={'xl'} balance center className={classes.title}>
          Deploy, test, and version integrations in full sync with your product
        </Heading>
        <Text size={'lg'} balance center className={classes.subtitle}>
          Our integration engine is API-first, fully observable, and seamlessly
          fits into your existing infrastructure and processes.
        </Text>
      </div>

      <Development />
    </section>
  )
}
